class ZIDXAccountInquiry implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountInquiryContainer";
    }
    getMatchURL(){
        return "/account/help/inquiry";
    }
    render(){
        // console.log("inquiry");
    }
}